.container2,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-right: auto;
	margin-left: auto
}

@media (min-width:576px) {

	.container2,
	.container-sm {
		max-width: 540px
	}
}

@media (min-width:768px) {

	.container2,
	.container-md,
	.container-sm {
		max-width: 720px
	}
}

@media (min-width:992px) {

	.container2,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 960px
	}
}

@media (min-width:1200px) {

	.container2,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1140px
	}
}

@media (min-width:1400px) {

	.container2,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1150px
	}
}
select {
	z-index: 0;
	padding-left: 60px;
	height: 35px;
	position: relative;
	border: none;
	width: 220px;
	display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
   
	border-radius: 30px;

	background-image: url("../Assets/homeimg/downarrow.png");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position: calc(100% - 8px) center;
}
label {
    position:absolute !important;
    z-index:1;
    height:35px;
    line-height:35px !important;
    pointer-events:none;
    text-indent:10px;
    font-weight:bold;
}
label, select {
    font-family:Arial;
    font-size:12px;
	text-align: center;
}

.spantag{
	display: inline-flex;
border: 2px solid #D4AC28;
border-radius: 30px;
padding: 3px;
}

.namelable{
	position: absolute;
padding-top: 10px;
}