@media (min-width:1400px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1320px;
	}
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 0;
	align-items: center
}

.m15{
  margin: 15px;
}

.bgtransparent{
    background-color: #fff0;
}
.custom-search-form{
    margin-top:5px;
}
.b-radius-30{
    border-radius: 30px;
}

.form-control {
  margin-top: 10px;
  height: 36px !important;
}

.nvbr:hover {
   
    border-bottom: 3px solid #D4AC28;
  }

  .bordercolor{
    border: 2px solid #D4AC28;
  }

  .bgcolorD4AC28{
     background-color: #D4AC28;
  }

  .joinusbtn{
    margin-top: 5px;
    padding: 4px;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    color: white;

  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #214545;

  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    
  }
  
  ul li {
    display: block;
    position: relative;
    float: left;
  
  }
  
  /* This hides the dropdowns */
  
  
  .dnone
   {
     display: none; 
     z-index: 999;
     
   }
  
  ul li a {
    display: block;
    padding: 1em;
    text-decoration: none;
    white-space: nowrap;
    color: #fff;
  }
  
  ul li a:hover { background: #ffffff; }
  
  /* Display the dropdown */
  

  @media(hover: none){
    li:hover > ul {
      display: block;
      position: absolute;
    }
}

  
  li:hover > ul {
    display: block;
    position: absolute;
  }
  
  li:hover li { float: none; }

  .subnav:hover .subnava{
    background-color: rgba(212, 172, 40, 0.26);;
    height: 40px;
    padding-top: 20px;
  }

  
  .mainnav:hover .subnav {
     background: #FEEDB5;;
     color: #214545;
     line-height: 0px;
     }

     .customeanchor{
      color: #214545;
     }
.customeanchor:hover{
  background: #f2bd1000 !important;
  color: #214545;
}
     
     .subnav:hover .mainnav>a {
      background: #fff;
      color: #214545;
      }

  
  li:hover li a:hover 
  { 
    background: #fff;color: #214545; 
  }
  
  .main-navigation li ul li { border-top: 0; }
  
  /* Displays second level dropdowns to the right of the first level dropdown */
  
  
  ul ul ul {
    left: 100%;
    top: 0;
  }
  
  /* Simple clearfix */
  
  
  
  ul:before,
  ul:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  
  ul:after { clear: both; }


  /* custome style for sub menu */

  .rowsection
  {
    margin-left: 13px;
    width: 600px;
    background-color:rgb(249, 249, 249)
  }


  input[type='radio'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border:1px solid darkgray;
    border-radius:50%;
    outline:none;
    background-color: #fff;
}

input[type='radio']:before {
  content: '';
display: block;
width: 141%;
height: 68%;
margin: 38%;
border-radius: 50%;
padding-left: 12px;
}
input[type='radio']:checked:before {
    background:#214545;
}

.popuplable {
  position: inherit !important;
  z-index: 1;
  height: 35px;
  line-height: 55px !important;
  pointer-events: none;
  text-indent: 10px;
  font-weight: bold;
  font-size: 14px !important;
}

.div1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid gold;
}

.div12 {
  width: 24%;
  background: silver;
}


