@font-face {
    font-family: 'Poppins Light';
    src: url('../Assets/fonts/FontsFree-Net-Poppins-Light.ttf');
  }

   .PoppinsLight
   {
    font-family: Poppins Light;
   }

.container2,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-right: auto;
	margin-left: auto
}


@media  (min-device-width: 320px) and (max-device-width: 480px)
{
	.container2,
	.container-sm {
		max-width: 540px
	}
    .associationsection
    {
        padding: 5px;
    }
    .carousel .slide img {
        width: 65%;
        vertical-align: top;
        border: 0;
        height: auto;
      }

      .bannertext{
        text-align: left;
        margin-top: 0px;
    }
    .contestimg
	{
		height: 160px !important;
	}
    .contestimgmobile
    {
        height: 370px !important; 
    }
    .communityimg2{
        height: auto;
        width: 100% !important;
    }

    .profilepic_home {
        height: 70px;
        width: 70px;
        margin-top: -50px;
        z-index:1;
        position: absolute;
        margin-left: 220px;
        border-radius: 50%;
      }  

      .Associationimg{
        height: 170px;
        width: 50%
       
      }

      .discoverborder{
        background:#d4ac28;
    padding-right: 15px;
    padding-top: 15px;
    width:275.33px;
    height: 297px;
    left:35.67px;
    top: 0px;
    position: absolute;
    }

    .youtuberow
    {
        width: 107%;
    }

    .contesrcarouseldiv{
        margin-left: '0px'
    }

   .customeslider
   {
    margin-left: -12px;
   }

   .footertext
   {
    font-size: 13px;
   }

   .dekstopimage{
    display: none !important;
   }
   .mobileimage{
    display: block;
   }
    
}

/* for medium device */
@media only screen  and (min-device-width: 393px) and (max-device-width: 851px)
{
    .discoverborder{
        background: #d4ac28;
        padding-right: 15px;
        padding-top: 15px;
        width: 321.33px;
        height: 297px;
        left: 23.67px;
        top: 0px;
        position: absolute;
    }
    .associationsection
    {
        padding: 1px;
    }
}

@media (min-width:768px) {

	.container2,
	.container-md,
	.container-sm {
		max-width: 720px
	}

    .carousel .slide .bannersliderimg {
            width: 80%;
            vertical-align: top;
            border: 0;
            height: 500px;

      }
      .associationsection
    {
        padding: 5px;
    }

      .bannertext{
        text-align: left;
        margin-top: 140px;
    }
    .contestimg
	{
		height: 271px !important;
	}
    .contestimgmobile
    {
        height: 400px !important; 
    }
    .communityimg2{
        height: auto;
        width: fit-content !important;
    }
    .profilepic_home {
        height: 70px;
        width: 70px;
        margin-top: -50px;
        z-index: 1;
        position: absolute;
        margin-left: 172px;
        border-radius: 50%;
      }  
      .Associationimg{
        height: 170px;
        width: 70%
       
      }

      .discoverborder{
        background: #d4ac28;
    padding-right: 15px;
    padding-top: 15px;
    width: 338.33px;
    height: 325px;
    left: 20.67px;
    top: 0px;
    position: absolute;
    }

    .youtuberow
    {
        width: 100%;
    }

    .contesrcarouseldiv{
        margin-left: '25px'
    }

    .customeslider
   {
    margin-left: 0px;
   }
   .footertext
   {
    font-size: 15px;
   }
   .dekstopimage{
    display: block;
   }
   .mobileimage{
    display: none !important;
   }
}

@media only screen  and (min-device-width: 1080px) and (max-device-width: 1499px)
{
    .container2,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1150px
	}

    .associationsection
    {
        padding: 10px;
    }

    .carousel .slide .bannersliderimg {
        width: 85%;
        vertical-align: top;
        border: 0;
        height: 440px;
      }

      .bannertext{
        text-align: left;
        margin-top: 140px;
    }
    .contestimg
	{
		height: 271px !important;
	}
    .contestimgmobile
    {
        height: 400px !important; 
    }
    .communityimg2{
        width: fit-content !important;
        height: auto;
    }
    .profilepic_home {
        height: 70px;
        width: 70px;
        margin-top: -50px;
        z-index: 1;
        position: absolute;
        margin-left: 172px;
        border-radius: 50%;
      }  
      .Associationimg{
        height: 170px;
        width: 100%
       
      }

      .discoverborder{
        background: #d4ac28;
    padding-right: 15px;
    padding-top: 15px;
    width: 338.33px;
    height: 325px;
    left: 20.67px;
    top: 0px;
    position: absolute;
    }
    .youtuberow
    {
        width: 100%;
    }
    .contesrcarouseldiv{
        margin-left: '25px'
    }

    .customeslider
    {
     margin-left: 0px;
    }
    .footertext
    {
     font-size: 17px;
    }

    .dekstopimage{
        display: block;
       }
       .mobileimage{
        display: none !important;
       }
    
}







.card {
    width: auto !important;
    border: 0px !important;
    border-radius: 0px !important;
  }

.font-15{
    font-size: 15px;
}
.marginleft-25
{
    margin-left: -25px;
}

.textright{
    text-align: right;
}
.textcenter{
    text-align: center;
}
.bannerimg{
    height: 450px;
}
.floatright{
    float: right;
}

.m-b-none{
    margin-bottom: 0px;
}

.p-l-none{
    padding-left: 0px;
}
.p-r-none{
    padding-right: 0px;
}
.b-radius{
    border-radius: 10px;
}
.b-radius-5{
    border-radius: 5px;
}
.b-radius-30{
    border-radius: 30px;
}
.pd-10{
    padding: 10px;
}
.pd-20{
    padding: 20px;
}
.b-none{
    border: none;
}
.banner2{
    height: 300px;
    width: 100%;
    margin-left: -10px;
}
.studentname
{
    display: block;
    width: 200px;
    word-wrap: break-word;
  
}

.whiltecolo{
    color: #fff;
}
.banner2pcolor{
    color: #868686;
}
.bgcolor{
    background-color: #D4AC28 !important;
}
.color214545
{
    color: #214545;
}
.color000{
    color:black
}
.color868686
{
    color: #868686;
}
.color8B752C{
    color: #8B752C;
}

.banner2bgcolor{
    background-color: #214545;
}
.bgcolorFFF8E6{
    background-color: #FFF8E6;
}
.bgcolorFFF8E682
{
    background-color: #FFF8E682;
}

.borderD4AC28
{
    border: 2px solid #D4AC28 !important;
}

.section2style{
    margin-top: 65px;
}
.enrollbtn{
    border: navajowhite;
border-radius: 5px;
padding-left: 10px;
padding-right: 10px;
padding-top: 4px;
padding-bottom: 4px;
width: 110px;
}



.discoverimg{
    height: 340px;
border-radius: 0px;
z-index: 3;
padding-top: 10px;
padding-right: 10px;
}

.cardbtn{
    padding-left: 15px;
    padding-right: 15px;
}

.toppic{
    height: 65px;
}

.authorclubsec{
    background-image:url('../Assets/homeimg/Vector6.png');
    /* background-attachment:fixed; */
    background-repeat: no-repeat;
    background-size: cover;
}

.commsection{
    padding-top: 60px;
padding-bottom: 70px;
}
.communityimg
{
    height: 70px;
    width: 70px;
}

.commubtm{
    background-color: #214545;
    color: #fff;
    border: none;
    padding: 10px;
}

.tweeterimg{
    height: 350px;
}

.hashtag1{
    text-align: end;
position: absolute;
width: 97%;
margin-top: 25px;
}

.hashtag12{
    text-align: end;
position: absolute;
width: 96%;
margin-top: 25px;
}

.hashrag2{
    background: #f9f9f9;

margin: -8px;

padding-left: 15px;
padding-right: 10px;
padding-bottom: 4px;
}


.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height .15s ease-in;
    border-radius: 10px;
  }
  .booksectionheight{
    height: 350px;
  }
  
  .carousel.carousel-slider .control-arrow:hover {
    background: none !important;
  }

  /* gallery slider button css */
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #D4AC28 !important;
    z-index: 9999;
    font-size: 40px !important;
  }

  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    cursor: pointer;
    color: #D4AC28 !important;
  }


  .carousel-indicators
  {
    display: none;
  }

  .carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6% !important;
    padding: 0;
    color: #f00;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
  }


  .carousel-control-prev-icon {
    /* background-image:url('../Assets/homeimg/leftarrow.png') !important; */
    background-size: 60% 60%;
  }
  .carousel-control-next-icon {
    /* background-image:url('../Assets/homeimg/rightarrow.png') !important; */
    background-size: 60% 60%;
  }

  .slick-next
  {
    display: none !important;
    right: 0px !important;

  }

  .slick-prev
  {
    display: none !important;
    right: 0px !important;

  }

  .slick-slide img {
    display: inline !important;
}