
@media (min-width:300px)
{
    .subdiv{
        position: absolute;
    width: auto;
    height: auto;
    left: 133px;
    top: 190px;
    
    background: #214545;
    border-radius: 20px;
    }
}

@media (min-width:768px) 
{
    .subdiv{
        position: absolute;
    width: auto;
    height: auto;
    left: 1000px;
    top: 190px;
    
    background: #214545;
    border-radius: 20px;
    }
}

@media only screen  and (min-device-width: 1080px) and (max-device-width: 1499px)
{
    .subdiv{
        position: absolute;
    width: auto;
    height: auto;
    left: 1000px;
    top: 190px;
    
    background: #214545;
    border-radius: 20px;
    }
}

.maindiv{
    
height: auto;
left: 235px;
top: 178px;

border-radius: 20px 0px 0px 20px;
}





.question:checked:before {
    background:none !important ;
}
