.card {
	width: 400px;
	border: none;
	border-radius: 12px
}

label.radio {
	cursor: pointer;
	width: 100%
}

label.radio input {
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	pointer-events: none
}

label.radio span {
	padding: 7px 14px;
	border: 2px solid #eee;
	display: inline-block;
	color: #039be5;
	border-radius: 10px;
	width: 100%;
	height: 48px;
	line-height: 27px
}

label.radio input:checked+span {
	border-color: #039BE5;
	background-color: #81D4FA;
	/* color: #fff; */
	border-radius: 9px;
	height: 48px;
	line-height: 27px
}
.btn-close{
    color: #fff !important;
}
.formfield
{
    border-bottom: 1px solid rgb(255, 255, 255) !important;
background-color: rgba(255, 0, 0, 0) !important;
border-top: 0px !important;
border-radius: 0px !important;
border-right: 0px !important;
border-left: 0px !important;
color: white;
}
.form-control {
	margin-top: 10px;
	height: 48px;
	
}

.form-control:focus {
	box-shadow: none;
	border: 2px solid #039BE5
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(255, 255, 255) ;

  }

.agree-text {
	font-size: 12px
}

.terms {
	font-size: 12px;
	text-decoration: none;
	color: #039BE5
}

.confirm-button {
	height: 50px;
	border-radius: 10px
}