@charset "UTF-8"; /* Set the encoding of the style sheet to Unicode UTF-8 */

#root{
  background: linear-gradient(180deg, #F9F0DA9C 0%, #F9F0DA 100%);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.flex-gap{
  display: inline-flex;
  flex-wrap: wrap;
}

.flex-gap > div {
  margin: 6px;
}

.bullettext{
  font-size: 10px;
  padding-top: 6px;
}

.contestuserrow{
  --bs-gutter-x: 0.5rem !important;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.swal-footer
{
  text-align: center !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.minheight
{
  min-height: 66vh;
}

.active{
  color: #214545 !important;
}

.text-left
{
  text-align: left;
}

.text-d-none
{
  text-decoration: none;
}

.f-s-10
{
  font-size: 10px;
}

.f-s-12
{
  font-size: 12px !important;
}
.f-s-13
{
  font-size: 13px !important;
}
.f-s-14
{
  font-size: 14px;
}
.f-s-15
{
  font-size: 15px;
}

.f-s-17
{
  font-size: 17px !important;
}

.f-s-20
{
  font-size: 20px;
}

.f-s-25{
  font-size: 25px !important;
}

.f-s-40{
  font-size: 40px !important;
}

.fw-bold-500
{
 
    font-weight: 500 !important;
  
}
.p-l-5{
  padding-left: 5px;
}
.p-l-0{
  padding-left: 0px !important;
}
.pd-10{
  padding: 10px;
}
.pd-20
{
  padding: 20px;
}
.pd-5{
  padding: 5px;
}
.p-b-10{
  padding-bottom: 10px;
}
.b-r-10P{
  border-radius: 10%;
 }
.b-r-10{
 border-radius: 10px;
}

.b-r-30{
  border-radius: 30px;
}
.b-r-50{
  border-radius: 50%;
}
.p-l-10
{
  padding-left: 10px;
}
.p-l-20
{
  padding-left: 20px;
}
.p-l-0{
  padding-left: 0px;
}
.p-r-10{
  padding-right: 10px;
}
.p-t-10
{
  padding-top: 10px;
}
.p-t-15
{
  padding-top: 15px;
}
.p-t-20
{
  padding-top: 20px;
}
.p-t-25
{
  padding-top: 25px !important;
}
.p-t-30
{
  padding-top: 30px;
}
.p-t-40
{
  padding-top: 40px;
}
.p-t-60
{
  padding-top: 60px !important;
}
.pb-20
{
  padding-bottom: 20px;
}
.p-b-5
{
  padding-bottom: 5px !important;
}
.p-b-0
{
padding-bottom: 0px !important;
}
.p-b-50
{
  padding-bottom: 50px;
}
.pb-40
{
  padding-bottom: 40px;
}

.ht-100{
  height: 100px;
}
.ht-40
{
  height: 40px;
}
.m-b-10
{
  margin-bottom: 10px;
}
.m-b-15
{
  margin-bottom: 15px;
}
.m-b-0
{
  margin-bottom: 0px;
}
.m-r-20
{
  margin-right: 20px;
}
.m-l-20
{
  margin-left: 20px;
}
.m-l-10
{
  margin-left: 10px;
}
.m-t-20
{
  margin-top: 20px;
}
.mt-15
{
  margin-top: 15px;
}

.m-10{
  margin: 10px;
}
.m-t-mi-25
{
  margin-top: -25px;
}
.m-l-mi-13
{
  margin-left: -13px;
}
.l-h-8
{
  line-height: 8px;
}

.l-h-10
{
  line-height: 10px;
}

.l-h-20
{
  line-height: 28px;
}
.w-100{
  width: 100%;
}

.h-100{
  height: 100%;
}
.h-300px
{
  height: 300px;
}
.text-right
{
  text-align: right;
}

.colorfff{
  color: #FFF !important;
}

.color000{
  color: #000000;
}
.colord4ac28
{
  color: #d4ac28;
}
.color214545{
  color: #214545;
}
.colorB9B9B9{
  color: #B9B9B9;
}

.colorD4AC28
{
  color: #D4AC28;
}

.facebookcolor{
  color: #3b5998;
}

.instagramcolor
{
  color: #E4405F;
}

.twettercolor
{
  color: #1DA1F2;
}

.youtubecolor
{
  color: #FF0000;
}

.linkedIncolor{
  color: #0077b5;
}
.bg464646
{
  color: #464646;
}
.color46464645{
  color: #46464645;
}

.bg868686
{
  color: #868686;
}
.b-r-50-p
{
  border-radius: 50%;
}
.b-r-5
{
  border-radius: 5px;
}

.b-r-30
{
  border-radius: 30px;
}

.border-1px
{
  border: 1px solid #D4AC2878 !important;
}

.fl-r{
  float: right;
}

.fl-l{
  float: left;
}
.bgcolorcolorB9B9B9
{
  background-color: #B9B9B9;
}

.bgcolorD9D9D9{
  background-color: #D9D9D9;
}
.bgcolorFAFAFA
{
  background-color: #FAFAFA;
}
.bgcolorECECEC
{
  background-color: #ECECEC;
}

.bgFFFCF2{
  background-color: #FFFCF2;
}
.bgcolorD4AC28
{
  background-color: #D4AC28;
}
.bgcolor000000{
  background-color: #000000;
}
.bgcolor214545
{
  background-color: #214545 !important;
}
.bgcolorFFF4CE
{
  background-color: #FFF4CE;
}
.bgFFF4CE59
{
  background-color: #FFF4CE59;;
}
.bgcolorFFFFFF
{
  background: #FFFFFF !important;
}
.t-decoration-none
{
  text-decoration: none;
}


.inline-flex
{
  display: inline-flex;
}


.scroll-to-top {
  background-color: #D4AC28 !important;
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
  border: none;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid #D4AC28 !important;
}
a:focus {
  outline: none;
}



.booksectionheight{
  height: 420px;
}

.bookimageheight{
  height: 200px;
}

/* load css here */

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
      url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
      no-repeat;
  z-index: 1;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(249, 249, 249, 0.96);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #D4AC28 transparent #D4AC28 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* alert popup css  */

.react-confirm-alert-button-group > button {
  outline: none !important;
  background: #D4AC28 !important;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center !important;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: auto !important;
  padding: 30px;
  /* text-align: left; */
  background: linear-gradient(180deg, #FFFDF8 0%, #FFF4CE 100%) !important; 
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  text-align: center !important;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flow-root !important;
  justify-content: flex-start;
  margin-top: 20px;
}