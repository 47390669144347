.bgD4AC28
{
    background-color: #D4AC28;
}
.colo8B752C{
    color: #8B752C;
}
.color214545{
    color: #214545 !important;
}
.font-25
{
    font-size: 25px;
}

.fixed-plugin {
    border-radius: 10px;
}

.fixed-plugin {
    position: fixed;
    top: 115px;
    right: 0;
    width: 65px;
    z-index: 3;
    border-radius: 8px 0 0 8px;
    text-align: center;
}

    .fixed-plugin img {
        border-radius: 0;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .fixed-plugin .btn.btn-link:hover,
    .fixed-plugin .btn.btn-link:focus,
    .fixed-plugin .btn.btn-link:active,
    .fixed-plugin .btn.btn-default.btn-link:hover,
    .fixed-plugin .btn.btn-default.btn-link:focus,
    .fixed-plugin .btn.btn-default.btn-link:active {
        background-color: transparent;
        color: #999999;
    }