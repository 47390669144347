
button.btn-load-more {
  padding: 7px 17px;
  margin: 20px 0 30px 0;
  cursor: pointer;
  border-radius: 5px;
}
  @media (min-width:300px)
  {
    .filtersection{
      display:block;
    }
    .yearsection{
      float: left;
      margin-bottom: 10px;
    }
    .monthsection{
      float: left;
      margin-bottom: 10px;
    }
    .sortbysection{
      float: left;
      margin-top: 15px;
    }

    .profilepic_story {
   
      border-radius: 50%;
      height: 70px;
      margin-left: 220px;
      margin-top: -50px;
      position: absolute;
      width: 70px;
      z-index: 999;
      }

      .storydropdown
      {
        padding-left: 155px !important;
        width: 303px !important;

      }
   
  }
  
  @media (min-width:768px) 
  {
    .filtersection{
      display:contents;
    }
    .yearsection{
      float: right;
      
    }
    .monthsection{
      float: right;
    }
    .sortbysection{
      float: right;
      margin-top: 0px;
    }

    .profilepic_story {
   
      border-radius: 50%;
      height: 70px;
      margin-left: 214px;
      margin-top: -50px;
      position: absolute;
      width: 70px;
      z-index: 999;
      }

      .storydropdown
      {
        padding-left: 58px !important;
        width: 220px !important;

      }
    
  }
  
  @media only screen  and (min-device-width: 1080px) and (max-device-width: 1499px)
  {
    .filtersection{
      display:contents;
    }
    .yearsection{
      float: right;
    }
    .monthsection{
      float: right;
    }
    .sortbysection{
      float: right;
      margin-top: 0px;
    }

    .profilepic_story {
   
      border-radius: 50%;
      height: 70px;
      margin-left: 168px;
      margin-top: -50px;
      position: absolute;
      width: 70px;
      z-index: 999;
      }
      .storydropdown
      {
        padding-left: 100px !important;
        width: 200px !important;

      }
  }
  

  @media only screen and (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5), 
       only screen and (min-width: 1280px) and (min-resolution: 144dpi) {
        .filtersection{
          display:contents;
        }
        .yearsection{
          float: right;
        }
        .monthsection{
          float: right;
        }
        .sortbysection{
          float: right;
          margin-top: 0px;
        }
    
        .profilepic_story {
       
          border-radius: 50%;
          height: 70px;
          margin-left: 169px;
          margin-top: -50px;
          position: absolute;
          width: 70px;
          z-index: 999;
          }
          .storydropdown
          {
            padding-left: 60px !important;
            width: 200px !important;
    
          }
}
  
  .minusbtn
  {
    display: inline-block;
    width:165px;
    margin-bottom:15px;
    text-align:center
  }