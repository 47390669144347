@media (min-width:300px)
{
.newsmainimg{
  height: 150px;
}
	
}

@media (min-width:768px) {
  .newsmainimg{
    height: 350px;
  } 
}

@media only screen  and (min-device-width: 1080px) and (max-device-width: 1499px)
{
  .newsmainimg{
    height: 350px;
  }
}



.checked {
    color: orange;
    padding: 2px;
  }

  .starratingsection{
    text-align: right;
padding-top: 30px;
padding-right: 10px;
  }

.readingtext
{
  white-space: pre-line;
}
  .paragraph-text{
    font-family: Poppins Light;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;

align-items: center;
text-align: justify;
/* white-space: break-spaces; */

  }


  
  .socialicon{
	background: #000;
color: #FFFFFF;
padding: 7px;
border-radius: 56%;
	}

  textarea::placeholder {
    color: #000;  
  }    