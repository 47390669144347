

.container2,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-right: auto;
	margin-left: auto
}


.boardofadvisoryimage
{
height: 100px;
width: auto;
}
.padding-desktop
{
	padding: 5rem;
}

@media (min-width:300px) {

	.container2,
	.container-sm {
		max-width: 540px
	}
	.aboutimg2{
		height: auto;
        width: 100%;
	}

	
	.meetteamsection{
		height:1800px;
	}
	.aboutheight{
		height: 1800px;
	}
	.aboutprofile{
		width: 50%;
		height: 150px;
	}
	.reviewerprifile
	{
		width: 50%;
		height: 170px;
	
	}
	.ourreviewers
	{
		margin-top: 0rem;
	}
}

@media (max-width:768px) {
	.padding-desktop
	{
		padding: 1rem;
	}
	.ourreviewers
	{
		margin-top: 1rem;
	}
}

@media (min-width:768px) {

	.container2,
	.container-md,
	.container-sm {
		max-width: 720px
	}

	.aboutimg2{
		height: 465px;
        width: 100%;
	}

	.meetteamsection
	{
		height:750px;
	}
	.aboutheight{
		height: 313px;
	}
	.aboutprofile
	{
		width: 72%;
		height: 120px;
	}

	.reviewerprifile
	{
		width: 80%;
		height: 130px;
	}

	.ourreviewers
	{
		margin-top: 0rem;
	}

}

@media only screen  and (min-device-width: 1080px) and (max-device-width: 1499px)
{
	.container2,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1150px
	}

	.aboutimg2{
		height: 465px;
        width: 100%;
	}
	.meetteamsection
	{
		height:750px;
	}
	.aboutheight{
		height: 313px;
	}
	.aboutprofile{
		width: 72%;
		height: 120px;
	}

	.reviewerprifile
	{
		width: 80%;
		height: 130px;
	}
}

.teamimg
{
	height: 225px;
}

 
  .lispan
  {
	font-size: 15px;
	font-weight: normal;
	margin-left: 60px;
	margin-bottom: 10px;
  }

.color868686{
    color: #868686;
}
.bgFEEDB5{
    background-color: #FEEDB5;
}
.bgFEEDB526{
    background-color: #FEEDB526;;
}

.border-radius-25
{
    border-radius: 25px !important;
}

.floatright{
    float: right;
}

.p-d-10{
    padding: 10px
}

.w-100-imp
{
	width: 100%!important;
}

.ourreviewers
{
	margin-top: 18rem;
}