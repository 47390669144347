@media (min-width:300px) {

	.applysection
    {
        padding-top: 15px; 
    }
}

@media (min-width:768px) {
.applysection
    {
        padding-top: 20px;  
    }
}

@media (min-width:992px) {

    .applysection
    {
        padding-top: 25px;  
    }	
}

@media (min-width:1200px) {

    .applysection
    {
        padding-top: 30px; 
    }
}

@media (min-width:1400px) {

	.applysection
    {
       padding-top: 30px; 
    }
}

.openingdiv{
    padding: 10px;
background: #FFFFFF;
border: 1px solid #F0F0F0;
box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
box-sizing: border-box;

height: auto;
}

.opntypeandsallry{
    vertical-align: super;
padding-left: 5px;
font-size: 12px;
}

.applybtn{
    background-color: #464646;
height: 24px;
color: #fff;
font-size: 11px;
padding-top: 3px;
padding-left: 20px;
padding-right: 20px;
border: none;
border-radius: 5px;
}

.opn_location{
    border: 1px solid;
border-radius: 30px;
padding-left: 10px;
padding-right: 10px;
margin-top: 10px;
}

