


@media (min-width:300px) {

	.storyreadingimg1{
    width: 100%;
    height: 200px;
    }
    .studentinfo{
        position: unset;
        width: 100%;
        height: 177px;
     
        top: 294px;
        background: #214545;
        border-radius: 20px 20px 20px 20px;
        margin-top: 9px;
        }

        .studentprofile {
          height: 80px;
          margin-left: 16px;
          width: 80px;
            margin-top: 10px;
          }

          .profilesummery {
            width: 200.78px;
            height: 14.16px;
            margin-left: 110px;
            margin-top: -44px;
            font-family: Poppins Light;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #fff;
          }

          .rightsection{
            padding-right: 10px;
          }
          .leftsection{
            padding-left: 10px;
          }
          .levelimg{
            height: 80px;
          }

          .Relatedprofilepic_story {
            border-radius: 50%;
            height: 70px;
            margin-left: 240px;
            margin-top: -50px;
            position: absolute;
            width: 70px;
            z-index: 999;
          }
}

@media (min-width:768px) {
    .storyreadingimg1{
      width: 80%;
      height: 290px;
    }
    .studentinfo{
        position: absolute;
        width: 425px;
        height: 177px;
        top: 247px;
        background: #214545;
        border-radius: 0px 20px 20px 0px;
        }

        .studentprofile {
          height: 80px;
          margin-left: 16px;
          width: 80px;
            margin-top: 15px;
          }

          .profilesummery {
            width: 200.78px;
            height: 14.16px;
            margin-left: 110px;
            margin-top: -44px;
            font-family: Poppins Light;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #fff;
          }
          .rightsection{
            padding-right: 0px;
          }
          .leftsection{
            padding-left: 0px;
          }

          .levelimg{
            height: 125px;
          }

          .Relatedprofilepic_story
          {
            border-radius: 50%;
            height: 70px;
            margin-left: 172px;
            margin-top: -50px;
            position: absolute;
            width: 70px;
            z-index: 999;
          }
}

@media only screen  and (min-device-width: 1080px) and (max-device-width: 1499px)
{
  .storyreadingimg1{
    width: 80%;
    height: 290px;
    }

    .studentinfo{
        position: absolute;
        width: 425px;
        height: 177px;
        top: 247px;
        background: #214545;
        border-radius: 0px 20px 20px 0px;
        }
        .studentprofile {
          height: 80px;
          margin-left: 16px;
          width: 80px;
            margin-top: 15px;
          }

          .profilesummery{
            width: 200.78px;
            height: 14.16px;
            margin-left: 110px;
            margin-top: -44px;
            font-family: Poppins Light;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #fff;
        }

        .rightsection{
          padding-right: 0px;
        }
        .leftsection{
          padding-left: 0px;
        }
        .levelimg{
          height: 125px;
        }

        .Relatedprofilepic_story
        {
          border-radius: 50%;
          height: 70px;
          margin-left: 172px;
          margin-top: -50px;
          position: absolute;
          width: 70px;
          z-index: 999;
        }
}


.checked {
    color: #D4AC28;
    padding: 2px;
  }

  .starratingsection{
    text-align: right;
padding-top: 35px !important;
padding-right: 10px;
  }

  .rating,.fs-star
  {
    color:#214545;
  }

  /* .paragraph-text{
   font-family: Poppins Light;
    font-family: 'Book Antiqua' !important;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
text-align: justify;
  } */

  .paragraph-text2 {
    font-family: Poppins Light;
    font-style: normal;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: justify;
   
    font-variation-settings: "opsz" 14;
    font-size: 0.875rem;
    font-weight: 350;
  }

  .paragraph-text-reading
  {
    font-family: Poppins Light;
    font-style: normal;
    /* line-height: 25px; */
    /* display: flex; */
    align-items: center;
    text-align: justify;
   
    font-variation-settings: "opsz" 14;
    font-size: 0.875rem;
    font-weight: 350; 
    white-space: break-spaces;
   
  }
  #paragraph-text-reading p{
    display :inline;
  }
  
  .socialicon{
	
padding: 7px;
border-radius: 56%;
	}

  textarea::placeholder {
    color: #000;  
  }    


  .commentsection{
    
position: relative;
display: flex;
flex-direction: column;
min-width: 0;
height: var(--mdb-card-height);
word-wrap: break-word;
background-color: var(--mdb-card-bg);
background-clip: border-box;
border: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
border-radius: var(--mdb-card-border-radius);

box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
   


  .star::before {
    content: "\2606";
    font-size: 2em;
  }
  
  .star.rated::before {
    content: "\2605";
    color: #D4AC28;
    font-size: 2em;
  }
