#contatti{
    background-color: #70c3be;
    letter-spacing: 2px;
    }
  #contatti a{
    color: #214545 !important;
    text-decoration: none;
  }
  
  
  @media (max-width: 575.98px) {
  
    #contatti{padding-bottom: 800px;}
    #contatti .maps iframe{
      width: 100%;
      height: 450px;
    }
   }
  
  
  @media (min-width: 576px) {
  
     #contatti{padding-bottom: 800px;}
  
     #contatti .maps iframe{
       width: 100%;
       height: 450px;
     }
   }
  
  @media (min-width: 768px) {
  
    #contatti{padding-bottom: 350px;}
  
    #contatti .maps iframe{
      width: 100%;
      height: 850px;
    }
  }
  
  @media (min-width: 992px) {
    #contatti{padding-bottom: 200px;}
  
     #contatti .maps iframe{
       width: 100%;
       height: 700px;
     }
  }
  
  
  #author a{
    color: #214545 !important;
    text-decoration: none;
      
  }

  