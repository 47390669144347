.WritingChallenge{
    position: absolute;
width: 276px;
height: 362px;
left: 235px;
top: 178px;
}

.imagenotfund{
  
width: 33.59px;
height: 33.59px;


background: url('../Assets/homeimg/imagenotfund.png');
}

.filetag{
    box-sizing: border-box;
    width: 300px;
    height: 300px;
    background: #FFFFFF;
    border: 0.5px solid #B9B9B9;
    border-radius: 20px;
}

.addcovertag{
 
    width: 96px;
    height: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #868686;
    margin-left: 20px;
    margin-top: 20px;
}

.filenotfound{
    width: 33.59px;
    height: 33.59px;
    margin-left: 125px;
    margin-top: 60px;
}

.fileselection{
 
    margin-left: 10px;
  margin-top: 80px;
}

.writingtextarea {
    min-height: calc(8.5em + .75rem + 2px) !important;
  }

  .writingdropdown{
    margin-left: 115px;
background-color: #FFF;
border: 1px solid #868686;
border-radius: 20px;
padding-left: 0px !important;
  }

  .writingbtn{
    padding-left: 20px;
padding-right: 20px;
padding-top: 2px;
padding-bottom: 2px;
  }

  .styletag{
    background: #FFFFFF;
border: 1px solid #868686;
border-radius: 20px;
  }

  