@media (min-width:300px) {

	.novel-storyreadingimg1{
    height: 200px;
    width: 100%;
    }
    .novel-studentinfo{
        position: unset;
        width: 315px;
        height: 177px;
       
        top: 294px;
        background: #214545;
        border-radius: 20px 20px 20px 20px;
        margin-top: 9px;
        }

        .novel-studentprofile {
            height: 84px;
            margin-left: 30px;
            width: 85px;
          }

          .novel-profilesummery {
            width: 200.78px;
            height: 14.16px;
            margin-left: 134px;
            margin-top: -44px;
            font-family: Poppins Light;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #fff;
          }

          .rightsection{
            padding-right: 10px;
          }
          .leftsection{
            padding-left: 10px;
          }
}

@media (min-width:768px) {
    .novel-storyreadingimg1{
      height: 290px;
      width: 80%;
    }
    .novel-studentinfo{
        position: absolute;
        width: 425px;
        height: 177px;
        top: 252px;
        background: #214545;
        border-radius: 0px 20px 20px 0px;
        }

        .novel-studentprofile {
            height: 84px;
            margin-left: 30px;
            width: 85px;
          }

          .novel-profilesummery {
            width: 200.78px;
            height: 14.16px;
            margin-left: 134px;
            margin-top: -44px;
            font-family: Poppins Light;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #fff;
          }

          .rightsection{
            padding-right: 0px;
          }
          .leftsection{
            padding-left: 0px;
          }
}


@media only screen  and (min-device-width: 1080px) and (max-device-width: 1499px)
{
  .novel-storyreadingimg1{
    height: 290px;
    width: 80%;
    }

    .novel-studentinfo{
        position: absolute;
        width: 425px;
        height: 177px;
        top: 252px;
        background: #214545;
        border-radius: 0px 20px 20px 0px;
        }
        .novel-studentprofile {
            height: 84px;
            margin-left: 55px;
            width: 85px;
          }

          .novel-profilesummery{
            width: 200.78px;
        height: 14.16px;
        margin-left: 157px;
        margin-top: -44px;
        font-family: Poppins Light;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #fff;
        }
        .rightsection{
          padding-right: 0px;
        }
        .leftsection{
          padding-left: 0px;
        }
}







.novelbtn{
    padding-left: 20px;
padding-right: 20px;
padding-top: 2px;
padding-bottom: 2px;
background-color: #E7E5E5;
}

.checked {
    color: orange;
    padding: 2px;
  }

  .novel-starratingsection{
    text-align: right;
padding-top: 30px;
padding-right: 10px;
  }


  .novel-paragraph-text{
    font-family: Poppins Light;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
text-align: justify;
  }
  .socialicon{

padding: 7px;
border-radius: 56%;
	}

  textarea::placeholder {
    color: #000;  
  }    

   
  .rating {
    padding: 20px;
  }

  .fa-star:hover{
    color: gold;
  }
  
  .fa-star-active:before {
    color: gold;
  }


  .fa-thumbs-o-up:hover{ 
    color:#000;
     -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3); 
  }

  .like:hover { 
    color:#000;
     -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3); 
  }